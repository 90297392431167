$principal: #012d3a;
$secundario: #e38e7b;

.nuevo {
  background-color: $secundario;
}
.descuento {
  background-color:$principal;
}

.product-content .product-price span:not(.old) {
    color: $secundario !important;
}

.shop-list-wrap .shop-list-content .product-list-price span {
    color: $secundario;
}

.product-details-content .product-details-price span {
    color: $secundario;
}

.main-menu nav ul li:hover a {
    color: $principal;
}

.main-menu nav ul li ul.submenu li:hover a {
    color: $principal;
}

.main-menu nav ul li ul.submenu li a::before {
    background: $principal none repeat scroll 0 0;
}

.shop-top-bar .shop-tab a.active, .shop-top-bar .shop-tab button.active {
    color: $secundario;
}

.product-wrap .product-img .product-action > div, .product-list-image-wrap .product-img .product-action > div {
    background-color: $principal;
}

.scroll-top {
    background-color: $secundario;
}

.shop-list-wrap .shop-list-content .shop-list-btn a:hover, .shop-list-wrap .shop-list-content .shop-list-btn button:hover {
    border: 1px solid $secundario;
}

.contact-social ul li a:hover {
    color: $principal;
}

.btn-hover a::after, .btn-hover button::after {
    background: $secundario;
}

.footer-widget .footer-list ul li a:hover {
    color: $secundario;
}

.sidebar-widget .sidebar-widget-list ul li a:hover > .checkmark, .sidebar-widget .sidebar-widget-list ul li a.active > .checkmark, .sidebar-widget .sidebar-widget-list ul li button:hover > .checkmark, .sidebar-widget .sidebar-widget-list ul li button.active > .checkmark {
    border-color: $secundario;
    background-color: $secundario;
}

.breadcrumb-content span > span > a:hover {
    color: $secundario;
}

a:hover {
    color: $secundario;
}
