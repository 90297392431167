/** GENERALES **/
.backgroundImage{
  /*background: url("../../../assets/img/background.jpg") no-repeat center center fixed;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-register-wrapper{
  background-color: white;
}

.error{
  color:red;
}

.logo-100 {
  max-width: 100%;
  height: auto;
}

.logo img{
  width: auto;
  max-height: 40px;
}

.movilMenuLink{
  font-weight: 600;
  display: block;
  padding: 10px 0;
  color: #111;
  text-transform: uppercase;
}

.breadcrumb-content > span {
    padding:10px;
    background-color: white;
}

.copyright{
  text-align: center;
}

.w100{
  width:100% !important;
}

/** GRID PRODUCTS **/
@media (max-width: 576px){
  .shop-bottom-area .grid.two-column .col-xl-4 {
      flex: 1 0 100% !important;
      max-width: 100% !important;
  }
}

.shop-list-content{
  padding: 10px !important;
}

.nuevo {
  background-color: #fa6bff;
}
.descuento {
  background-color: #a749ff;
}

.product-img{
  text-align: center !important;
}

.product-wrap{
    padding-bottom: 10px;
    box-shadow: 0 0 10px 3px #ccc!important;
}

.shop-list-wrap {
    box-shadow: 0 0 10px 3px #ccc!important;
}

.product-list-image-wrap .product-img img, .product-wrap .product-img img {
    /*width: auto !important;*/
    height: 360px !important;
    object-fit: cover !important;
}

.product-large-image-wrapper .swiper-slide  img{
  /*width: auto !important;*/
  height: 200px !important;
  object-fit: cover !important;
}

.single-image img.img-fluid {
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
}

.product-large-image-wrapper .swiper-slide{
  text-align: center !important;
}

.product-content .product-price span:not(.old) {
    font-size: 24px !important;
    line-height: 1 !important;
    color: #fe5252 !important;
}
.product-wrap .product-content .product-price span::before, .product-list-image-wrap .product-content .product-price span::before {
    position: unset !important;
    top: unset !important;
    right: unset !important;
    width: unset !important;
    height: unset !important;
    content: unset !important;
    background-color: unset !important;
}

.product-wrap .product-img .product-action .pro-quickview, .product-list-image-wrap .product-img .product-action .pro-quickview {
    width: unset !important;
}

.related-product-area .swiper-container{
  width: 100% !important;
}

.related-product-area .swiper-wrapper{
  margin-top:15px;
  margin-bottom: 15px;
}

.boton{
  background-color: #012d3a !important;
  color: white !important;
}

.boton:hover, .boton:active{
  background-color: #043e50 !important;
}
